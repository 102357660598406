import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watchSaga() {
  yield takeLatest(actions.LIQPAY_ACCOUNT_GET_REQUEST, watchResourceGetRequest)
  yield takeLatest(actions.LIQPAY_ACCOUNT_CREATE_REQUEST, watchResourceCreateRequest)
}

function* watchResourceGetRequest() {
  try {
    const response = yield call(Api.LiqpayAccounts.get)
    yield put(actions.liqpayAccountGetSuccess(response.data))
  } catch (error) {
    yield put(actions.liqpayAccountGetFailure(error))
  }
}
function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.LiqpayAccounts.create, payload)
    yield put(actions.liqpayAccountCreateSuccess(response.data))
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.liqpayAccountCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
