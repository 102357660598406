import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { networkGetRequest, networkUpdateRequest } from '../../store/networks/actions'
import { vendorCreateRequest, vendorDeleteRequest } from '../../store/vendors/actions'

import { NetworkInfo } from '../../components/Network'
import FeedbacksContainer from './FeedbacksContainer'

const NetworkContainer = () => {
  const [openModalFromNotifications, setOpenModalFromNotifications] = useState(false)
  const [modalVendorId, setModalVendorId] = useState(null)

  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const network = useSelector((state) => state.networks.network)
  const { items: vendors, fetching: vendorsFetching } = useSelector((state) => state.vendors)

  const getNetwork = () => dispatch(networkGetRequest(networkId))
  const updateNetwork = (data) => dispatch(networkUpdateRequest(data))
  const createVendor = (data) => dispatch(vendorCreateRequest(data, () => setShowActivationForm(true)))
  const deleteVendor = (data) => dispatch(vendorDeleteRequest(data))

  const handleCloseModal = () => {
    navigate({ state: null })
    setOpenModalFromNotifications(false)
  }

  useEffect(() => {
    getNetwork()
  }, [])

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setOpenModalFromNotifications(true)
      setModalVendorId(location.state.id)
    }
  }, [location.state])

  return (
    <>
      <NetworkInfo
        network={network}
        vendors={vendors}
        updateNetwork={updateNetwork}
        createVendor={createVendor}
        deleteVendor={deleteVendor}
        vendorsFetching={vendorsFetching}
      />
      {openModalFromNotifications && (
        <FeedbacksContainer id={modalVendorId} isShown={openModalFromNotifications} closeModal={handleCloseModal} />
      )}
    </>
  )
}

export default NetworkContainer
