import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Text, Button, Input, TextArea, colors, Switch } from '../../UIkit'
import { HintIcon, NetworkIcon } from '../../UIkit/svgs'
import { Validator } from '../../../lib'
import { handleValidate } from '../../../lib/validationHelper'

const NetworkForm = ({ createNetwork }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    name: '',
    url: 'https://pinka.coffee',
    description: '',
    id: localStorage.getItem('ownerId'),
    checkboxIntegrated: false
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
  })

  const validationFields = [
    ['name', Validator.concepts.isFilled, [form.name]],
    ['description', Validator.concepts.isFilled, [form.description]],
  ]

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      id: form.id,
      network: {
        name: form.name,
        url: form.url,
        description: form.description,
        checkboxIntegrated: form.checkboxIntegrated,
      }
    }

    handleValidate(validationFields)
      .then(() => createNetwork(data, () => clearLocalStorage()))
      .catch((errors) => setFormErrors(errors))
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('ownerId')
  }

  const handleChangeCheckboxIntegrated = () => {
    setForm((prev) => ({ ...prev, checkboxIntegrated: !form.checkboxIntegrated }))
  }

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <NetworkIcon />
        <Text variant='subheading'>{t('signup:networkForm:title')}</Text>
      </div>
      <Input
        id='network-name'
        name='name'
        placeholder={t('input:networkName')}
        value={form.name}
        onChange={handleUpdateField}
      />
      <TextArea
        id='about'
        name='description'
        placeholder={t('input:about')}
        value={form.description}
        onChange={handleUpdateField}
        rows={8}
        error={formErrors.description}
      />
      <div className='auth-page__form__switch'>
        <Switch
          index={1}
          label={t('input:checkboxIntegrated')}
          checked={form.checkboxIntegrated}
          onChange={handleChangeCheckboxIntegrated}
        />
        <div className='auth-page__form__hint'>
          <HintIcon data-rh={t('hint:checkboxIntegration')} />
        </div>
      </div>
      <Button type='submit' disabled={form.name == ''}>
        {t('btn:signup')}
      </Button>
      <div className='auth-page__form__description'>
        <Text variant={'body-secondary1'} color={colors.gray[500]}>
          {t('signup:userForm:haveAccount')}&nbsp;
        </Text>
        <Text variant={'body-secondary1'}>
          <Link to='/admin'>{t('btn:signin')}</Link>
        </Text>
      </div>
    </form>
  )
}

NetworkForm.propTypes = {
  userData: PropTypes.object.isRequired,
  createNetwork: PropTypes.func.isRequired,
}

export default NetworkForm
