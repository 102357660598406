import React from 'react'
import { colors, Text } from '../../UIkit'

import './styles.scss'

const LiqpayAccountInfo = ({ liqpayAccount }) => {
  return (
    <div className='liqpay-account-info'>
      <div className='liqpay-account-info__title'>
        <Text variant='body-secondary1' color={colors.gray[500]}>ВАШІ ДАНІ LIQPAY</Text>
      </div>
      <div className='liqpay-account-info__content'>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            ID:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.id}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Name:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.name}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Company:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.company}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            IBAN:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.iban}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            OKPO:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.okpo}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Phone:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.phone}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Email:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.email}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Description:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.description}
          </Text>
        </div>
        <div className='liqpay-account-info__text-component'>
          <Text variant='body-secondary2'>
            Created at:
          </Text>
          <Text variant='body-secondary1'>
            {liqpayAccount.created_at}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default LiqpayAccountInfo
