import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Text, colors } from '../UIkit'
import { SETTINGS_SECTIONS } from '../../constants'
import { UserIcon, Bell, Wallet } from '../UIkit/svgs'

import './styles.scss'

const Sections = ({ activeSection, setActiveSection, setOpen }) => {
  const { t } = useTranslation()

  const handleChangeSection = (section) => () => {
    setActiveSection(section)
    isMobile && setOpen(true)
  }

  return (
    <div>
      <div
        className={activeSection == SETTINGS_SECTIONS.profile && !isMobile ? 'section-active' : 'section'}
        onClick={handleChangeSection(SETTINGS_SECTIONS.profile)}
      >
        <UserIcon width={48} height={48} />
        <Text variant='subheading'>{t('settings:editProfile:title')}</Text>
      </div>

      {/* <div
        className={activeSection == SETTINGS_SECTIONS.withdrawal && !isMobile ? 'section-active' : 'section'}
        onClick={handleChangeSection(SETTINGS_SECTIONS.withdrawal)}
      >
        <div className='section__icon'>
          <Wallet width={18} height={16} color={colors.primary[500]} />
        </div>
        <Text variant='subheading'>{t('settings:withdrawalMethod:title')}</Text>
      </div> */}

      <div
        className={activeSection == SETTINGS_SECTIONS.liqpay && !isMobile ? 'section-active' : 'section'}
        onClick={handleChangeSection(SETTINGS_SECTIONS.liqpay)}
      >
        <div className='section__icon'>
          <Wallet color={colors.primary[500]} />
        </div>
        <Text variant='subheading'>{t('settings:liqpay:title')}</Text>
      </div>
      <div
        className={activeSection == SETTINGS_SECTIONS.notifcations && !isMobile ? 'section-active' : 'section'}
        onClick={handleChangeSection(SETTINGS_SECTIONS.notifcations)}
      >
        <div className='section__icon'>
          <Bell width={24} height={24} color={colors.primary[500]} />
        </div>
        <Text variant='subheading'>{t('settings:notifications:title')}</Text>
      </div>
    </div>
  )
}

export default Sections
