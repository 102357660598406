import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import './styles.scss'
import { Text } from '../UIkit'
import {
  AddBaristaIcon,
  AddLiqpayIcon,
  AddVendorIcon, CompleteFour, CompleteOne, CompleteThree, CompleteTwo,
  FillMenuIcon, NotReadyIcon,
  ReadyIcon
} from '../../assets/svgs/networkActivation'
import { Arrow, ArrowUp } from '../UIkit/svgs'

const NetworkActivation = ({
  stepsCount, stepsNames
}) => {
  const { t } = useTranslation()
  const [firstOpened, setFirstOpened] = useState(false)
  const [secondOpened, setSecondOpened] = useState(false)
  const [thirdOpened, setThirdOpened] = useState(false)
  const [fourthOpened, setFourthOpened] = useState(false)

  const renderWebContent = () => (
    <div className='network-activation__content'>
      <div className='network-activation__component'>
        <div className='network-activation__icon-component'>
          <img src={AddVendorIcon} alt='Add Vendor'/>
        </div>
        <div className='network-activation__text-component'>
          <NavLink
            className='network-activation__nav-link'
            to='/network'
            children={() => {
              return (
                <Text variant='subheading'>{t('networkActivation:titles:addVendor')}</Text>
              )
            }}
          />
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addVendor')}</Text>
        </div>
        <div className='network-activation__ready-icon'>
          <img src={stepsNames.includes('vendors') ? ReadyIcon : NotReadyIcon} alt='Ready check'/>
        </div>
      </div>
      <div className='network-activation__component'>
        <div className='network-activation__icon-component'>
          <img src={AddBaristaIcon} alt='Add Barista'/>
        </div>
        <div className='network-activation__text-component'>
          <NavLink
            className='network-activation__nav-link'
            to='/employees'
            children={() => {
              return (
                <Text variant='subheading'>{t('networkActivation:titles:addBarista')}</Text>
              )
            }}
          />
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addBarista')}</Text>
        </div>
        <div className='network-activation__ready-icon'>
          <img src={stepsNames.includes('baristas') ? ReadyIcon : NotReadyIcon} alt='Ready check'/>
        </div>
      </div>
      <div className='network-activation__component'>
        <div className='network-activation__icon-component'>
          <img src={FillMenuIcon} alt='Fill Menu'/>
        </div>
        <div className='network-activation__text-component'>
          <NavLink
            className='network-activation__nav-link'
            to='/menu'
            children={() => {
              return (
                <Text variant='subheading'>{t('networkActivation:titles:addMenu')}</Text>
              )
            }}
          />
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addMenu')}</Text>
        </div>
        <div className='network-activation__ready-icon'>
          <img src={stepsNames.includes('products') ? ReadyIcon : NotReadyIcon} alt='Ready check'/>
        </div>
      </div>
      <div className='network-activation__component'>
        <div className='network-activation__icon-component'>
          <img src={AddLiqpayIcon} alt='Add LiaPay'/>
        </div>
        <div className='network-activation__text-component'>
          <NavLink
            className='network-activation__nav-link'
            state={{ tab: 'liqpay' }}
            to='/settings'
            children={() => {
              return (
                <Text variant='subheading'>{t('networkActivation:titles:addLiqpay')}</Text>
              )
            }}
          />
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addLiqpay')}</Text>
        </div>
        <div className='network-activation__ready-icon'>
          <img src={stepsNames.includes('liqpay') ? ReadyIcon : NotReadyIcon} alt='Ready check'/>
        </div>
      </div>
    </div>
  )

  const renderMobileContent = () => (
    <div className='network-activation__content'>
      <div className='network-activation__component' onClick={() => setFirstOpened(!firstOpened)}>
        <div className='network-activation__top-component'>
          <div className='network-activation__ready-icon'>
            <img src={stepsNames.includes('vendors') ? ReadyIcon : CompleteOne} alt='Ready check'/>
          </div>
          <div className='network-activation__text-component'>
            <Text className='network-activation__nav-link' variant='subheading'>
              {t('networkActivation:titles:addVendor')}
            </Text>
          </div>
          <div className='network-activation__arrow'>
            {firstOpened ? <ArrowUp /> : <Arrow />}
          </div>
        </div>
        {firstOpened && <div className='network-activation__bottom-component'>
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addBarista')}</Text>
        </div>}
      </div>
      <div className='network-activation__component' onClick={() => setSecondOpened(!secondOpened)}>
        <div className='network-activation__top-component'>
          <div className='network-activation__ready-icon'>
            <img src={stepsNames.includes('baristas') ? ReadyIcon : CompleteTwo} alt='Ready check'/>
          </div>
          <div className='network-activation__text-component'>
            <Text className='network-activation__nav-link' variant='subheading'>
              {t('networkActivation:titles:addBarista')}
            </Text>
          </div>
          <div className='network-activation__arrow'>
            {secondOpened ? <ArrowUp/> : <Arrow/>}
          </div>
        </div>
        {secondOpened && <div className='network-activation__bottom-component'>
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addBarista')}</Text>
        </div>}
      </div>
      <div className='network-activation__component' onClick={() => setThirdOpened(!thirdOpened)}>
        <div className='network-activation__top-component'>
          <div className='network-activation__ready-icon'>
            <img src={stepsNames.includes('products') ? ReadyIcon : CompleteThree} alt='Ready check'/>
          </div>
          <div className='network-activation__text-component'>
            <Text className='network-activation__nav-link' variant='subheading'>
              {t('networkActivation:titles:addMenu')}
            </Text>
          </div>
          <div className='network-activation__arrow'>
            {thirdOpened ? <ArrowUp/> : <Arrow/>}
          </div>
        </div>
        {thirdOpened && <div className='network-activation__bottom-component'>
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addMenu')}</Text>
        </div>}
      </div>
      <div className='network-activation__component' onClick={() => setFourthOpened(!fourthOpened)}>
        <div className='network-activation__top-component'>
          <div className='network-activation__ready-icon'>
            <img src={stepsNames.includes('liqpay') ? ReadyIcon : CompleteFour} alt='Ready check'/>
          </div>
          <div className='network-activation__text-component'>
            <Text className='network-activation__nav-link' variant='subheading'>
              {t('networkActivation:titles:addLiqpay')}
            </Text>
          </div>
          <div className='network-activation__arrow'>
            {fourthOpened ? <ArrowUp/> : <Arrow/>}
          </div>
        </div>
        {fourthOpened && <div className='network-activation__bottom-component'>
          <Text variant='body-secondary1'>{t('networkActivation:bodyText:addLiqpay')}</Text>
        </div>}
      </div>
    </div>
  )

  return (
    <div className='network-activation'>
      <div className='network-activation__title'>
        <Text variant='h3-medium'>{t('networkActivation:title', { step: stepsCount || 0 })}</Text>
      </div>
      {isMobile ? renderMobileContent() : renderWebContent()}
    </div>
  )
}

export default NetworkActivation
