import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { RouteNames } from '../../../../router'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { colors, TableActions, TableCell, TableRow, Text } from '../../../UIkit'
import { Shown, VerticalDots } from '../../../UIkit/svgs'
import TableText from '../../components/TableText'

const OwnerRow = ({ owner }) => {
  const actionsRef = useRef()
  const history = useNavigate()
  const [showActions, setShowActions] = useState(false)

  const toggleActions = useCallback(() => {
    setShowActions((prev) => !prev)
  }, [setShowActions])

  useOnClickOutside(actionsRef, () => setShowActions(false))

  const navigateToNetwork = useCallback(() => {
    history(`${RouteNames.STAFF_NETWORKS}/${owner?.network.id}`)
  }, [history, RouteNames, owner])

  return  owner ? (
    <TableRow>
      <TableCell>
        <TableText text={owner.profile.fullName} />
      </TableCell>
      <TableCell>
        <TableText text={owner.email} />
      </TableCell>
      <TableCell>
        <TableText text={owner.phoneNumber} />
      </TableCell>
      <TableCell>
        <TableText text={owner.liqpayAccountPhone} />
      </TableCell>
      <TableCell>
        <TableText text={moment(owner.createdAt).format('DD-MM-YYYY')} />
      </TableCell>
      <TableCell>
        <div ref={actionsRef}>
          <VerticalDots className='employees__dots-icon' onClick={toggleActions} />
          {showActions && (
            <TableActions elemRef={actionsRef.current}>
              <div className='employees__action' onClick={navigateToNetwork}>
                <Shown color={colors.primary[500]} />
                <Text variant='body-main2'>Show network</Text>
              </div>
            </TableActions>
          )}
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell>
        <Text variant='subheading'>Owner data is not available</Text>
      </TableCell>
    </TableRow>
  )
}

export default OwnerRow
