import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import { toCamelCase } from '../../lib/converter'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.NETWORK_CREATE_REQUEST, watchResourceCreateRequest)
  yield takeLatest(actions.NETWORK_GET_REQUEST, watchResourceGetRequest)
  yield takeLatest(actions.NETWORK_UPDATE_REQUEST, watchResourceUpdateRequest)

  yield takeLatest(actions.POSTER_CREATE_REQUEST, watchPosterCreateRequest)
  yield takeLatest(actions.POSTER_DELETE_REQUEST, watchPosterDeleteRequest)

  yield takeLatest(actions.ACTIVATION_INDEX_REQUEST, watchActivationIndexRequest)
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Network.get, payload)

    yield put(actions.networkGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.networkGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Network.update, payload)

    yield put(actions.networkUpdateSuccess(toCamelCase(response.data)))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.networkUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceCreateRequest({ payload, successCallback, history }) {
  try {
    const response = yield call(Api.Network.create, payload)

    localStorage.setItem('networkId', response.data.id)
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('phoneNumber')

    yield put(actions.networkCreateSuccess(response.data))
    if (successCallback) successCallback()
    notifications.createNotification('create-success')
    history('/admin')
  } catch (error) {
    yield put(actions.networkCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchPosterCreateRequest({ payload: { data, history } }) {
  try {
    const response = yield call(Api.Poster.create, data)
    history('/menu')
    yield put(actions.posterCreateSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.posterCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchPosterDeleteRequest({ payload: history }) {
  try {
    const response = yield call(Api.Poster.delete)
    history('/menu')
    yield put(actions.posterDeleteSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.posterDeleteFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchActivationIndexRequest() {
  try {
    const response = yield call(Api.NetworkActivation.get)
    yield put(actions.activationIndexSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.activationIndexFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
