import {
  LIQPAY_ACCOUNT_GET_REQUEST,
  LIQPAY_ACCOUNT_GET_SUCCESS,
  LIQPAY_ACCOUNT_GET_FAILURE,
  LIQPAY_ACCOUNT_CREATE_REQUEST,
  LIQPAY_ACCOUNT_CREATE_SUCCESS,
  LIQPAY_ACCOUNT_CREATE_FAILURE,
} from './actions'

const initialState = {
  fetching: false,
  liqpayAccount: {},
  error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LIQPAY_ACCOUNT_GET_REQUEST:
      return { ...state, fetching: true, error: null }
    case LIQPAY_ACCOUNT_GET_SUCCESS:
      return { ...state, fetching: false, liqpayAccount: payload }
    case LIQPAY_ACCOUNT_GET_FAILURE:
      return { ...state, fetching: false, error: payload }
    case LIQPAY_ACCOUNT_CREATE_REQUEST:
      return { ...state, fetching: true }
    case LIQPAY_ACCOUNT_CREATE_SUCCESS:
      return { ...state, fetching: false, liqpayAccount: payload, error: null }
    case LIQPAY_ACCOUNT_CREATE_FAILURE:
      return { ...state, fetching: false, error: payload }

    default:
      return state
  }
}
