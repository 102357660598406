const FALLBACK_CARD = { gaps: [4, 8, 12], lengths: [16], code: { size: 3 } }

const limitLength = (string = '', maxLength) => string.substr(0, maxLength)

const removeNonNumber = (string = '') => string.replace(/[^\d]/g, '')

const addGaps = (string = '', gaps) => {
  const offsets = [0].concat(gaps).concat([string.length])

  return offsets.map((end, index) => {
    if (index === 0) return ''
    const start = offsets[index - 1]
    return string.substr(start, end - start)
  }).filter(part => part !== '').join(' ')
}

const formatNumber = (number) => {
  const numberSanitized = removeNonNumber(number)
  const maxLength = FALLBACK_CARD.lengths[FALLBACK_CARD.lengths.length - 1]
  const lengthSanitized = limitLength(numberSanitized, maxLength)
  const formatted = addGaps(lengthSanitized, FALLBACK_CARD.gaps)

  return formatted
}

const formatExpiry = expiry => {
  const sanitized = limitLength(removeNonNumber(expiry), 4)
  if (sanitized.match(/^[2-9]$/)) { return `0${sanitized}` }
  if (sanitized.length > 2) { return `${sanitized.substr(0, 2)}/${sanitized.substr(2, sanitized.length)}` }
  return sanitized
}

const formatCVC = (cvc) => {
  const maxCVCLength = FALLBACK_CARD.code.size
  return limitLength(removeNonNumber(cvc), maxCVCLength)
}

export default object => ({
  holder: object.holder,
  expires: formatExpiry(object.expires),
  cvv: formatCVC(object.cvv),
  number: formatNumber(object.number),
  default: object.default,
})

