
export const LIQPAY_ACCOUNT_GET_REQUEST = 'LIQPAY_ACCOUNT_GET_REQUEST'
export const LIQPAY_ACCOUNT_GET_SUCCESS = 'LIQPAY_ACCOUNT_GET_SUCCESS'
export const LIQPAY_ACCOUNT_GET_FAILURE = 'LIQPAY_ACCOUNT_GET_FAILURE'

export const LIQPAY_ACCOUNT_CREATE_REQUEST = 'LIQPAY_ACCOUNT_CREATE_REQUEST'
export const LIQPAY_ACCOUNT_CREATE_SUCCESS = 'LIQPAY_ACCOUNT_CREATE_SUCCESS'
export const LIQPAY_ACCOUNT_CREATE_FAILURE = 'LIQPAY_ACCOUNT_CREATE_FAILURE'

export const liqpayAccountGetRequest = () => ({
  type: LIQPAY_ACCOUNT_GET_REQUEST
})

export const liqpayAccountGetSuccess = (payload) => ({
  type: LIQPAY_ACCOUNT_GET_SUCCESS,
  payload
})

export const liqpayAccountGetFailure = (error) => ({
  type: LIQPAY_ACCOUNT_GET_FAILURE,
  error
})

export const liqpayAccountCreateRequest = (payload) => ({
  type: LIQPAY_ACCOUNT_CREATE_REQUEST,
  payload
})

export const liqpayAccountCreateSuccess = (payload) => ({
  type: LIQPAY_ACCOUNT_CREATE_SUCCESS,
  payload
})

export const liqpayAccountCreateFailure = (error) => ({
  type: LIQPAY_ACCOUNT_CREATE_FAILURE,
  error
})
