import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Button, colors, Input, Text, TextArea } from '../../UIkit'
import { formatPhoneNumber, Validator } from '../../../lib'
import './styles.scss'
import { handleValidate } from '../../../lib/validationHelper'
import { HintIcon } from '../../UIkit/svgs'

const LiqpayAccountForm = ({ network, currentSession, createLiqpayAccount }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    name: network.name || '',
    url: 'https://pinka.coffee',
    publicKey: 'some_key',
    privateKey: 'some_key',
    description: network.description || '',
    phone: currentSession.phone_number || '+380 (',
    email: currentSession.email || '',
    iban: '',
    okpo: '',
    company: ''
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
    phone: '',
    email: '',
    iban: '',
    okpo: '',
    company: ''
  })

  const validationFields = [
    ['name', Validator.concepts.isFilled, [form.name]],
    ['description', Validator.concepts.isFilled, [form.description]],
    ['phone', Validator.concepts.phoneRegex, [form.phone]],
    ['email', Validator.concepts.isFilled, [form.email]],
    ['iban', Validator.concepts.isFilled, [form.iban]],
    ['okpo', Validator.concepts.edrpouRegex, [form.okpo]],
    ['company', Validator.concepts.isFilled, [form.company]],
  ]

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  const handleUpdatePhone = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: formatPhoneNumber(target.value) }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      liqpayAccount: {
        phone: form.phone,
        email: form.email,
        iban: form.iban.replace(/\s/g, ''),
        okpo: form.okpo,
        publicKey: form.publicKey,
        privateKey: form.privateKey,
        company: form.company,
        description: form.description,
        site: form.url,
        name: form.name
      }
    }

    handleValidate(validationFields)
      .then(() => createLiqpayAccount(data))
      .catch((errors) => setFormErrors(errors))
  }

  const isDisabledSave = () => {
    const { name, description, phone, email, iban, okpo, company } = form

    return [name, description, phone, email, iban, okpo, company].includes('')
  }

  return (
    <form className='liqpay-account' onSubmit={handleSubmit}>
      <div className='liqpay-account__title'>
        {isMobile
          ? <Text variant='body-secondary1' color={colors.gray[500]}>{t('hint:liqpayAccount')}</Text>
          : <>
            <Text variant='body-secondary1' color={colors.gray[500]}>{t('settings:liqpay:title').toUpperCase()}</Text>
            <div className='liqpay-account__hint'>
              <HintIcon data-rh={t('hint:liqpayAccount')}/>
            </div>
          </>
        }
      </div>
      <Input
        id='lqipay-name'
        name='name'
        placeholder={t('input:networkName')}
        value={form.name}
        onChange={handleUpdateField}
      />
      <Input
        id='email'
        name='email'
        placeholder={t('input:email')}
        value={form.email}
        onChange={handleUpdateField}
        error={formErrors.email}
      />
      <Input
        id='phone'
        name='phone'
        placeholder={t('input:phone')}
        value={form.phone}
        onChange={handleUpdatePhone}
        error={formErrors.phone}
      />
      <Input
        id='iban'
        name='iban'
        placeholder={t('input:iban')}
        value={form.iban}
        onChange={handleUpdateField}
        error={formErrors.iban}
      />
      <Input
        id='company'
        name='company'
        placeholder={t('input:recipientBank')}
        value={form.company}
        onChange={handleUpdateField}
        error={formErrors.company}
      />
      <Input
        id='okpo'
        name='okpo'
        placeholder={t('input:edrpou')}
        value={form.okpo}
        onChange={handleUpdateField}
        error={formErrors.okpo}
      />
      <TextArea
        id='about'
        name='description'
        placeholder={t('input:about')}
        value={form.description}
        onChange={handleUpdateField}
        rows={8}
        error={formErrors.description}
      />
      <div className='liqpay-account__button'>
        <Button type='submit' disabled={isDisabledSave()}>
          {t('btn:save')}
        </Button>
      </div>
    </form>
  )
}

export default LiqpayAccountForm
