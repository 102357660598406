import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NetworkActivation from '../../components/NetworkActivation'
import { activationIndexRequest } from '../../store/networks/actions'

const NetworkActivationContainer = () => {
  const dispatch = useDispatch()
  const { activationStepsCount: stepsCount, activationSteps: activationSteps } = useSelector((state) => state.networks)

  useEffect(() => {
    dispatch(activationIndexRequest())
  }, [])

  return (
    <NetworkActivation
      stepsCount={stepsCount}
      stepsNames={activationSteps}
    />
  )
}

export default NetworkActivationContainer
